@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body{
  font-family: 'PT Sans', sans-serif;
  /* background: #fefbea; */
}
/* .react-tel-input { */
 
/* } */

.blinking-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red; /* Replace 'red' with the desired color */
  animation: blink 1s infinite; /* The animation will last 1 second and repeat infinitely */
}

/* Define the animation */
@keyframes blink {
  0%, 100% { opacity: 0; } /* At the start and end, the circle will be invisible (opacity: 0) */
  50% { opacity: 1; } /* At 50% of the animation, the circle will be fully visible (opacity: 1) */
}

.hand-drawn-underline {
  position: relative;
  display: inline-block;
}

.hand-drawn-underline::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 4px;
  background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 50%, black 50%, black 75%, transparent 75%, transparent);
  background-size: 4px 4px;
}

